(function () {
    function isBrowserSupported() {
        'use strict';
        var isEs5 = Function.prototype.bind && !this;

        try {
            eval('var bar = (x) => x + 1');
            eval('Promise.resolve(5);');
            return isEs5;
        } catch (error) {
            console.log(error.message);
            return false;
        }
    }

    if (!isBrowserSupported()) {
        window.location.href = '/pages/unsupported_browser.html';
    }
})();
